<template>
  <div>
    <div class="text-left">
      <div class="d-flex justify-content-between mb-4">
        <h3 class="f-bold cl-primary">Detail Packages</h3>
        <p
          class="cl-primary align-self-center"
          v-on:click="toList()"
          style="cursor: pointer"
        >
          List Packages
        </p>
      </div>

      <div class="d-flex justify-content-around" v-if="loading">
        <loader-component></loader-component>
      </div>

      <div v-if="!loading">
        <div class="row">
          <div class="col-sm-3">
            <h6 class="mb-0 cl-secondary">Code Packages</h6>
          </div>
          <div class="col-sm-9 text-secondary">{{ data.code }}</div>
        </div>
        <hr />
        <div class="row">
          <div class="col-sm-3">
            <h6 class="mb-0 cl-secondary">Description</h6>
          </div>
          <div class="col-sm-9 text-secondary">
            {{ data.package_detail.description }}
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-sm-3">
            <h6 class="mb-0 cl-secondary">Shipping Address</h6>
          </div>
          <div class="col-sm-9 text-secondary">
            {{ data.package_detail.shipping_address }}
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-sm-3">
            <h6 class="mb-0 cl-secondary">Date Input</h6>
          </div>
          <div class="col-sm-9 text-secondary">
            {{ moment(data.date_submission).format("DD/MM/YYYY") }}
          </div>
        </div>
        <hr />
        <div class="row" v-if="data.status_approval">
          <div class="col-sm-3">
            <h6 class="mb-0 cl-secondary">Approval Status</h6>
          </div>
          <div class="col-sm-9 text-secondary">
            <span
              class="badge badge-success p-2"
              v-if="data.status_approval == 'approved'"
              >{{ data.status_approval }}</span
            >
            <span
              class="badge badge-warning p-2"
              v-if="data.status_approval == 'pending'"
              >{{ data.status_approval }}</span
            >
            <span
              class="badge badge-danger p-2"
              v-if="data.status_approval == 'rejected'"
              >{{ data.status_approval }}</span
            >
            <span
              class="badge badge-secondary p-2"
              v-if="data.status_approval == 'waiting'"
              >{{ data.status_approval }}</span
            >
          </div>
        </div>
        <hr />
        <div v-if="data.status_payment !== 'waiting'">
          <div class="row">
            <div class="col-sm-3">
              <h6 class="mb-0 cl-secondary">Payment Status</h6>
            </div>
            <div class="col-sm-9 text-secondary">
              <span
                class="badge badge-success p-2"
                v-if="data.status_payment == 'paid'"
                >Approved Full Payment</span
              >
              <span
                class="badge badge-secondary p-2"
                v-if="data.status_payment == 'down payment 50%'"
                >Down Payment</span
              >
              <span
                class="badge badge-primary p-2"
                v-if="data.status_payment == 'waiting'"
                >Waiting</span
              >
              <span
                class="badge badge-warning p-2"
                v-if="data.status_payment == 'pending'"
                >Pending</span
              >
              <span
                class="badge badge-danger p-2"
                v-if="data.status_payment == 'rejected'"
                >Rejected</span
              >
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
    <div v-if="data.package_invoice && data.status_invoice !== 'waiting'">
      <div v-if="data.package_invoice.length !== 0">
        <div class="row text-left">
          <div class="col-sm-12">
            <h6 class="cl-secondary">Invoices admin</h6>
          </div>
          <div class="col-sm-12">
            <div class="w-100">
              <table class="table table-bordered w-100 cl-secondary">
                <thead>
                  <th>Description</th>
                  <th>Carton</th>
                  <th>Cbm</th>
                  <th>Price Per CBM</th>
                  <th>Total</th>
                </thead>
                <tr v-for="(inv, i) in data.package_invoice" :key="i">
                  <td>{{ inv.description }}</td>
                  <td>{{ inv.carton }}</td>
                  <td>{{ inv.cbm }}</td>
                  <td>{{ inv.price_per_cbm }}</td>
                  <td>{{ inv.total }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div v-if="data.not_match_invoice">
      <div
        v-if="
          data.not_match_invoice.length !== 0 &&
          data.status_checking_warehouse == 1
        "
      >
        <div class="row text-left">
          <div class="col-sm-12">
            <h6>Not Match Result</h6>
          </div>
          <div class="col-sm-12">
            <div class="w-100">
              <table class="table table-bordered w-100">
                <thead>
                  <th>Description</th>
                  <th>Carton</th>
                  <th>Cbm</th>
                </thead>
                <tr v-for="(inv, i) in data.not_match_invoice" :key="i">
                  <td>{{ inv.description }}</td>
                  <td>{{ inv.carton }}</td>
                  <td>{{ inv.cbm }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div v-if="data.revised_invoice">
      <div v-if="data.revised_invoice.length !== 0">
        <div class="row text-left">
          <div class="col-sm-12">
            <h6>Revised Invoice</h6>
          </div>
          <div class="col-sm-12">
            <div class="w-100">
              <table class="table table-bordered w-100">
                <thead>
                  <th>Description</th>
                  <th>Carton</th>
                  <th>Cbm</th>
                  <th>Price Per CBM</th>
                  <th>Total</th>
                </thead>
                <tr v-for="(inv, i) in data.revised_invoice" :key="i">
                  <td>{{ inv.description }}</td>
                  <td>{{ inv.carton }}</td>
                  <td>{{ inv.cbm }}</td>
                  <td>{{ inv.price_per_cbm }}</td>
                  <td>{{ inv.total }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div v-if="data.payment_proof">
      <div v-if="data.payment_proof.length !== 0">
        <div class="row text-left">
          <div class="col-sm-3">
            <h6 class="mb-0 cl-secondary">Proof of payment user</h6>
          </div>
          <div class="col-sm-9 text-secondary">
            <div v-for="(proof, i) in data.payment_proof" :key="i">
              <a
                :href="proof.image"
                target="_blank"
                v-if="proof.type !== 'pay_in_indonesia'"
                >View - {{ proof.description }}
              </a>
              <p v-if="proof.type == 'pay_in_indonesia'">Pay in Indonesia</p>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div v-if="data.package_detail">
      <div class="row text-left">
        <div class="col-sm-3">
          <h6 class="mb-0 cl-secondary">Tracking Number</h6>
        </div>
        <div class="col-sm-9 text-secondary">
          <p>
            {{
              data.package_detail.tracking_number
                ? data.package_detail.tracking_number
                : " - "
            }}
          </p>
        </div>
      </div>
      <hr />
    </div>
    <div v-if="data.promotion_detail">
      <div class="row text-left">
        <div class="col-sm-3">
          <h6 class="mb-0 cl-secondary">Promotion</h6>
        </div>
        <div class="col-sm-9 text-secondary">
          <p>
            {{
              data.promotion_detail.code ? data.promotion_detail.code : " - "
            }}
          </p>
        </div>
      </div>
      <hr />
    </div>
    <div v-if="data.delivery_proof">
      <div v-if="data.delivery_proof.length != 0">
        <div class="row text-left">
          <div class="col-sm-3">
            <h6 class="mb-0 cl-secondary">Picture Delivery</h6>
          </div>
          <div v-for="(delv, i) in data.delivery_proof" :key="i">
            <a :href="delv.image_1" target="_blank">View File 1</a> -
            <a :href="delv.image_2" target="_blank">View File 2</a>
            <p target="_blank">Recipient Name: {{ delv.recipient_name }}</p>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>
<script>
import LoaderComponent from "../../../components/Loader.vue";
export default {
  components: { LoaderComponent },
  props: ["code"],
  name: "DetailPackages",
  data() {
    return {
      data: {},
      loading: true,
    };
  },

  mounted() {
    console.log(this.code);
    this.getDetailPackages();
    this.scrollToTop();
  },

  methods: {
    getDetailPackages() {
      this.loading = true;
      const endpoint = "customer-package/customer/detail/" + this.code;
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.customer_package;
          console.log(this.data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    toList() {
      this.$router.push("/customers/my-packages");
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
